<template>
<span>

    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">
            <img v-if="company.comp_logo" :src="`${base_url}upload/company/${company.comp_logo}`" class="logo">
        </v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey" dark class="mb-2" :to="{name: 'company'}">
                <v-icon dark>keyboard_arrow_left</v-icon> Voltar
            </v-btn>
        </template>
    </v-toolbar>

    <table id="table-detalhe" class="table table-bordered table-condensed">
        <tbody>
            <tr>
                <th width="10%">Idioma:</th>
                <td width="90%" colspan="5">
                    <img v-if="company.language" :src="`${base_url}upload/language/${company.language.lang_flag}`" class="avatar">
                </td>
            </tr>
            <tr>
                <th width="10%">Razão Social:</th>
                <td width="90%" colspan="5">{{ company.comp_razaosocial }}</td>
            </tr>
            <tr>
                <th width="10%">Fantasia:</th>
                <td width="90%" colspan="5">{{ company.comp_fantasia }}</td>
            </tr>
            <tr>
                <th width="10%">Copyrigh:</th>
                <td width="40%" colspan="2">{{ company.comp_copyright }}</td>
                <th width="10%">Domínio:</th>
                <td width="40%" colspan="2">{{ company.comp_domínio }}</td>
            </tr>
            <tr>
                <th width="10%">Cod. Analytics:</th>
                <td colspan="2">{{ company.comp_google_analytics }}</td>
                <th width="10%">Facebook:</th>
                <td colspan="2">{{ company.comp_link_facebook }}</td>
            </tr>
            <tr>
                <th width="10%">Instagram:</th>
                <td colspan="2">{{ company.comp_link_instagram }}</td>
                <th width="10%">Twitter:</th>
                <td colspan="2">{{ company.comp_link_twitter }}</td>
            </tr>
            <tr>
                <th width="10%">LGPD</th>
                <td colspan="2">{{ (company.comp_lgpd == '1') ? 'Ativo' : 'Inativo' }}</td>
                <th width="10%">Status</th>
                <td colspan="2">{{ (company.comp_status == '1') ? 'Ativo' : 'Inativo' }}</td>
            </tr>
            <tr>
                <th width="10%">Link LGPD:</th>
                <td width="90%" colspan="5">{{company.comp_link_lgpd}}</td>
            </tr>
            <tr>
                <th width="10%">Palavras Chave:</th>
                <td width="90%" colspan="5">{{company.comp_palavras_chaves}}</td>
            </tr>
            <tr>
                <th width="10%">Descrição:</th>
                <td width="90%" colspan="5">{{company.comp_descricao}}</td>
            </tr>
        </tbody>
    </table>

</span>
</template>

<script>
import {
    URL_BASE
} from '../../../config/configs'

export default {
    created() {
        this.loadCompany()
    },
    name: 'DetailCompanyComponent',
    props: {
        comp_id: {
            require: true
        }
    },
    data() {
        return {
            search: '',
            company: {},
            base_url: URL_BASE
        }
    },
    methods: {
        loadCompany() {
            this.$store.dispatch('loadCompany', this.comp_id)
                .then(response => {
                    this.company = response
                })
                .catch(error => {
                    this.$swal({
                        position: 'center',
                        icon: 'error',
                        title: 'Erro',
                        text: 'Dados não localizado',
                        showConfirmButton: true,
                        timer: 6000
                    })
                })
        }
    }
}
</script>

<style scoped>
.avatar {
    height: 25px;
}

.logo {
    height: 50px;
}
</style>
